import React from 'react';
import {
  Typography,
  Box,
  Container,
  Section,
  Layout,
  Grid,
  RawHTML,
} from '../../components';
import { policyData } from '../../config/data';
const PrivacyPolicyPage = () => {
  return (
    <Layout title="Quizzy Privacy Policy">
      <Container mt={{ xs: 8, md: 12 }}>
        <Section>
          <Grid gridTemplateColumns={{ _: '1fr', sm: '3fr 2fr' }}>
            <Box>
              <Typography variant="heading1" mb={1}>
                Privacy Policy
              </Typography>
            </Box>
          </Grid>
        </Section>
        <Section>
          <RawHTML html={policyData} />
        </Section>
      </Container>
    </Layout>
  );
};

export default PrivacyPolicyPage;
